import React, { useEffect, useState } from 'react';
import s from './Main.module.css';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import question1 from '../../images/quetion-1.svg';
import question2 from '../../images/quetion-2.svg';
import { startQuiz } from '../../utils/api';

export default function Main({ page, loader, spiceId }) {
  const navigate = useNavigate();

  const convertPage = (number) => {
    switch (number) {
      case 1:
        return 'two';
      case 2:
        return 'three';
      case 3:
        return 'four';
      case 4:
        return 'five';
      case 5:
        return 'six';
      case 6:
        return 'final';
      default:
        return 'one';
    }
  };

  const start = () => {
    startQuiz(spiceId, page)
      .then(() => {
        navigate(`/${convertPage(page)}`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className={s.main}>
      <div className={s.content}>
        <h1 className={s.title}>
          <img src={question2} alt="" className={s.question1} />
          Привет, на связи Q CLUB!
        </h1>

        <p className={s.subtitle}>
          Мы с вами уже больше года, а вас с нами – больше миллиона. Спасибо,
          что остаетесь c нами.
        </p>
        <div className={s.container}>
          <p className={s.subtitle}>
            <img src={question1} alt="" className={s.question2} />
            Теперь давайте проверим, как хорошо вы знаете Q CLUB.
          </p>
        </div>
      </div>
      <div className={s.button}>
        <Button text="Начать" type="button" onClick={start} loader={loader} />
      </div>
    </div>
  );
}
