import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import s from './App.module.css';
import Main from './pages/Main/Main';
import One from './pages/One/One';
import Two from './pages/Two/Two';
import Three from './pages/Three/Three';
import Four from './pages/Four/Four';
import Five from './pages/Five/Five';
import Six from './pages/Six/Six';
import Final from './pages/Final/Final';
import { getUrlParameter } from './utils/api';

import Footer from './components/Footer/Footer';
import { getQuestionNumber } from './utils/api';

export default function App() {
  // const url = window.location.origin;
  const searchParams = new URLSearchParams(window.location.search);
  const spiceId = searchParams.get('spice_id');

  // const spiceId = 7891891;
  const url = 'https://iqosmeetingbot-test.cleverbots.ru';

  const [pageNumber, setPageNumber] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getQuestionNumber(spiceId)
      .then((res) => {
        setPageNumber(res.data.data.question);
      })
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={s.app}>
      <Routes>
        <Route
          path="/"
          element={
            <Main
              spiceId={spiceId}
              page={pageNumber}
              url={url}
              loader={loader}
            />
          }
        />
        <Route path="/one" element={<One spiceId={spiceId} url={url} />} />
        <Route path="/two" element={<Two spiceId={spiceId} url={url} />} />
        <Route path="/three" element={<Three spiceId={spiceId} url={url} />} />
        <Route path="/four" element={<Four spiceId={spiceId} url={url} />} />
        <Route path="/five" element={<Five spiceId={spiceId} url={url} />} />
        <Route path="/six" element={<Six spiceId={spiceId} url={url} />} />
        <Route path="/final" element={<Final spiceId={spiceId} />} />
      </Routes>
      <Footer />
    </div>
  );
}
