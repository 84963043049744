import React, { useState, useEffect } from 'react';
import s from './Three.module.css';
import QuestionsBar from '../../components/QuestionsBar/QuestionsBar';
import Button from '../../components/Button/Button';
import one from '../../images/number1.svg';
import two from '../../images/number2.svg';
import five from '../../images/number5.svg';
import Modal from '../../components/Modals/Modal';
import ErrorModal from '../../components/Modals/ErrorModal';
import RadioAnswer from '../../components/Answer/RadioAnswer';
import { sendData } from '../../utils/api';
import { date } from '../../utils/constants';

export default function Three({ spiceId }) {
  const questions = [
    { text: 'Одно', img: one },
    { text: 'Два', img: two },
    { text: 'Пять', img: five },
  ];

  const correctAnswer = 'Два';

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [answer, setAnswer] = useState();
  const [correct, setCorrect] = useState();
  const [checked, setChecked] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    for (let value of data.values()) {
      const sendingData = {
        question_3: value,
        status: 'Опрос прерван',
        spice_id: spiceId,
        survey: 'Task_Education',
        last_answer_time: date,
      };
      sendData(sendingData).then(() => {
        checkAnswer(value);
      });
    }
  };

  const checkAnswer = (answer) => {
    if (answer) {
      if (answer === correctAnswer) {
        setAnswer(answer);
        setCorrect(true);
        setTimeout(() => {
          setModal(true);
        }, 1000);
      } else {
        setAnswer(answer);
        setCorrect(false);
        setTimeout(() => {
          setErrorModal(true);
        }, 1000);
      }
    }
  };

  return (
    <>
      <div className={s.one}>
        <QuestionsBar questionsNumber={6} activeQuestions={3} />
        <h1 className={s.title}>
          Сколько всего специальных предложений можно выбрать в программе
          лояльности?
        </h1>
        <form
          className={s.form}
          id="three"
          onSubmit={onSubmit}
          onChange={(e) => {
            setChecked(e.target.value);
          }}
        >
          {questions.map((item) => {
            return (
              <RadioAnswer
                text={item.text}
                img={item.img}
                key={item.text}
                correct={correct}
                answer={answer}
                check={checked}
              />
            );
          })}
        </form>
        <Button text="Ответить" form="three" type="submit" />
      </div>
      {modal && (
        <Modal
          title="Да вы настоящий эксперт Q CLUB!"
          text="2 специальных предложения на выбор ждут вас каждые 3 месяца в разделе «Мои предложения»."
          boldText="Перейдем к следующему вопросу?"
          buttonText="Продолжим"
          nextQuestion="four"
        />
      )}
      {errorModal && (
        <ErrorModal
          title="Упс, немного мимо."
          text="На самом деле участник Q CLUB может выбрать 2 специальных предложения из доступных каждые 3 месяца. Правда, здорово?"
          boldText="Идем дальше?"
          buttonText="Продолжим"
          nextQuestion="four"
        />
      )}
    </>
  );
}
