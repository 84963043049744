import React, { useState, useEffect } from 'react';
import s from './Six.module.css';
import QuestionsBar from '../../components/QuestionsBar/QuestionsBar';
import Button from '../../components/Button/Button';
import plusOne from '../../images/plus-one.svg';
import star from '../../images/Star1.svg';
import starAndCircle from '../../images/star-and-circle.svg';
import Modal from '../../components/Modals/Modal';
import ErrorModal from '../../components/Modals/ErrorModal';
import RadioAnswer from '../../components/Answer/RadioAnswer';
import { sendData } from '../../utils/api';
import { date } from '../../utils/constants';
import { sendEventAnalitic } from '../../utils/api';

export default function Six({ spiceId }) {
  const questions = [
    {
      text: 'Вы получаете +1 дополнительное специальное предложение на выбор',
      img: plusOne,
    },
    { text: 'Ваш друг получает специальное предложение на выбор', img: star },
    {
      text: 'Вы получаете баллы OZON, а друг — дополнительное предложение на выбор',
      img: starAndCircle,
    },
  ];

  const correctAnswer =
    'Вы получаете баллы OZON, а друг — дополнительное предложение на выбор';

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [answer, setAnswer] = useState();
  const [correct, setCorrect] = useState();
  const [checked, setChecked] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    for (let value of data.values()) {
      const sendingData = {
        question_6: value,
        status: 'Опрос завершен',
        spice_id: spiceId,
        survey: 'Task_Education',
        last_answer_time: date,
      };
      sendData(sendingData).then(() => {
        sendEventAnalitic('passed_all_scenario');
        checkAnswer(value);
      });
    }
  };

  const checkAnswer = (answer) => {
    if (answer) {
      if (answer === correctAnswer) {
        setAnswer(answer);
        setCorrect(true);
        setTimeout(() => {
          setModal(true);
        }, 1000);
      } else {
        setAnswer(answer);
        setCorrect(false);
        setTimeout(() => {
          setErrorModal(true);
        }, 1000);
      }
    }
  };
  return (
    <>
      <div className={s.one}>
        <QuestionsBar questionsNumber={6} activeQuestions={6} />
        <h1 className={s.title}>
          Вы можете приглашать своих совершеннолетних друзей в программу
          лояльности, а что происходит после их вступления в Q CLUB?
        </h1>
        <form
          className={s.form}
          id="six"
          onSubmit={onSubmit}
          onChange={(e) => {
            setChecked(e.target.value);
          }}
        >
          {questions.map((item) => {
            return (
              <RadioAnswer
                text={item.text}
                img={item.img}
                key={item.text}
                correct={correct}
                answer={answer}
                check={checked}
              />
            );
          })}
        </form>
        <Button text="Ответить" form="six" type="submit" />
      </div>
      {modal && (
        <Modal
          title="Вау, все правильно!"
          text="Вы точно знаете, что делиться с друзьями* лучшим – это просто! За каждую успешную рекомендацию** вы можете получить баллы OZON, а ваш друг – дополнительное специальное предложение на выбор. И все это доступно по кнопке «Пригласить друга»."
          boldText="Узнаем результаты викторины?"
          buttonText="Узнать результаты"
          nextQuestion="final"
        />
      )}
      {errorModal && (
        <ErrorModal
          title="Не совсем так."
          text="За каждую успешную рекомендацию вы можете получить баллы OZON, а ваш друг – дополнительное специальное предложение на выбор. И все это доступно по кнопке «Пригласить друга»."
          boldText="Узнаем результаты викторины?"
          buttonText="Узнать результаты"
          nextQuestion="final"
        />
      )}
    </>
  );
}
