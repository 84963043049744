import React, { useState, useEffect } from 'react';
import s from './Four.module.css';
import QuestionsBar from '../../components/QuestionsBar/QuestionsBar';
import Button from '../../components/Button/Button';
import check from '../../images/check.svg';
import cross from '../../images/cross.svg';
import Modal from '../../components/Modals/Modal';
import ErrorModal from '../../components/Modals/ErrorModal';
import RadioAnswer from '../../components/Answer/RadioAnswer';
import { sendData } from '../../utils/api';
import { date } from '../../utils/constants';

export default function Four({ spiceId }) {
  const questions = [
    { text: 'Да', img: check },
    { text: 'Нет', img: cross },
  ];

  const correctAnswer = 'Да';

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [answer, setAnswer] = useState();
  const [correct, setCorrect] = useState();
  const [checked, setChecked] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    for (let value of data.values()) {
      const sendingData = {
        question_4: value,
        status: 'Опрос прерван',
        spice_id: spiceId,
        survey: 'Task_Education',
        last_answer_time: date,
      };
      sendData(sendingData).then(() => {
        checkAnswer(value);
      });
    }
  };

  const checkAnswer = (answer) => {
    if (answer) {
      if (answer === correctAnswer) {
        setAnswer(answer);
        setCorrect(true);
        setTimeout(() => {
          setModal(true);
        }, 1000);
      } else {
        setAnswer(answer);
        setCorrect(false);
        setTimeout(() => {
          setErrorModal(true);
        }, 1000);
      }
    }
  };

  return (
    <>
      <div className={s.one}>
        <QuestionsBar questionsNumber={6} activeQuestions={4} />
        <h1 className={s.title}>
          Итак, если вы уже использовали все доступные специальные предложения в
          Q CLUB, можно ли получить еще?
        </h1>
        <form
          className={s.form}
          id="four"
          onSubmit={onSubmit}
          onChange={(e) => {
            setChecked(e.target.value);
          }}
        >
          {questions.map((item) => {
            return (
              <RadioAnswer
                text={item.text}
                img={item.img}
                key={item.text}
                correct={correct}
                answer={answer}
                check={checked}
              />
            );
          })}
        </form>
        <Button text="Ответить" form="four" type="submit" />
      </div>
      {modal && (
        <Modal
          title="Совершенно верно!"
          text="В разделе «Мои задания» можно выбрать задание и получить +1 дополнительное специальное предложение на выбор за его выполнение. Кстати, эта викторина тоже считается!"
          boldText="Готовы идти дальше?"
          buttonText="Готов!"
          nextQuestion="five"
        />
      )}
      {errorModal && (
        <ErrorModal
          title="Все наоборот!"
          text="Участники Q CLUB могут получать дополнительные специальные предложения на выбор за выполнение заданий в разделе «Мои задания». Кстати, эта викторина тоже считается!"
          boldText="Продолжим?"
          buttonText="Продолжим"
          nextQuestion="five"
        />
      )}
    </>
  );
}
