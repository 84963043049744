import React, { useState, useEffect } from 'react';
import s from './Answer.module.css';

export default function Answer({ text, img, difference, answered }) {
  const [value, setValue] = useState(false);
  const [correct, setCorrect] = useState();
  // const [answered, setAnswered] = useState(false);

  useEffect(() => {
    if (difference) {
      // setAnswered(true);
      if (difference.includes(text)) {
        setCorrect(true);
      } else {
        setCorrect(false);
      }
    }
  }, [difference]);

  return (
    <label
      className={`${s.answer} ${value && s.checked} ${correct && answered ? s.false : ''} ${!correct && answered ? s.true : ''}`}
      type='button'
    >
      <div className={`${s.circle} ${correct && answered ? s.false : ''} ${!correct && answered ? s.true : ''}`}>
        <img src={img} alt='' className={s.img} />
      </div>
      <p className={`${s.text} ${correct && answered ? s.false : ''} ${!correct && answered ? s.true : ''}`}>{text}</p>
      <input
        type='checkbox'
        name={text}
        checked={value}
        className={s.input}
        onChange={() => {
          setValue(!value);
        }}
      />
    </label>
  );
}
