import React, { useState, useEffect } from 'react';
import s from './Two.module.css';
import QuestionsBar from '../../components/QuestionsBar/QuestionsBar';
import Button from '../../components/Button/Button';
import one from '../../images/1.svg';
import three from '../../images/3.svg';
import cross from '../../images/cross.svg';
import Modal from '../../components/Modals/Modal';
import ErrorModal from '../../components/Modals/ErrorModal';
import RadioAnswer from '../../components/Answer/RadioAnswer';
import { sendData } from '../../utils/api';
import { date } from '../../utils/constants';

export default function Two({ spiceId, url }) {
  const questions = [
    { text: 'Каждый месяц', img: one },
    { text: 'Раз в 3 месяца', img: three },
    { text: 'Никогда', img: cross },
  ];

  const correctAnswer = 'Раз в 3 месяца';

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [answer, setAnswer] = useState();
  const [correct, setCorrect] = useState();
  const [checked, setChecked] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    for (let value of data.values()) {
      const sendingData = {
        question_2: value,
        status: 'Опрос прерван',
        spice_id: spiceId,
        survey: 'Task_Education',
        last_answer_time: date,
      };
      sendData(sendingData).then(() => {
        checkAnswer(value);
      });
    }
  };

  const checkAnswer = (answer) => {
    if (answer) {
      if (answer === correctAnswer) {
        setAnswer(answer);
        setCorrect(true);
        setTimeout(() => {
          setModal(true);
        }, 1000);
      } else {
        setAnswer(answer);
        setCorrect(false);
        setTimeout(() => {
          setErrorModal(true);
        }, 1000);
      }
    }
  };

  return (
    <>
      <div className={s.one}>
        <QuestionsBar questionsNumber={6} activeQuestions={2} />
        <h1 className={s.title}>
          Как часто в Q CLUB обновляются специальные предложения?
        </h1>
        <form
          className={s.form}
          id="two"
          onSubmit={onSubmit}
          onChange={(e) => {
            setChecked(e.target.value);
          }}
        >
          {questions.map((item) => {
            return (
              <RadioAnswer
                text={item.text}
                img={item.img}
                key={item.text}
                correct={correct}
                answer={answer}
                check={checked}
              />
            );
          })}
        </form>
        <Button text="Ответить" form="two" type="submit" />
      </div>
      {modal && (
        <Modal
          title="В яблочко!"
          text="Мы и правда обновляем специальные предложения раз в 3 месяца. Надеемся, вы успели оценить их в разделе «Мои предложения»."
          boldText="Идём дальше?"
          buttonText="Продолжим"
          nextQuestion="three"
        />
      )}
      {errorModal && (
        <ErrorModal
          title="Не совсем так."
          text="Специальные предложения в Q CLUB обновляются раз в 3 месяца. Кстати, их можно выбрать в разделе «Мои предложения». Но сначала давайте завершим игру."
          boldText="Продолжим?"
          buttonText="Продолжим"
          nextQuestion="three"
        />
      )}
    </>
  );
}
