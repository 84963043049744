import React from 'react';
import s from './QuestionsBar.module.css';

export default function QuestionsBar({ activeQuestions, questionsNumber }) {
  //   const [questions, setQuestions] = useState();

  //   //   useEffect(() => {
  //   //     setQuestions(questionsNumber);
  //   //   }, []);

  return (
    <div className={s.bar}>
      {Array(questionsNumber)
        .fill(0)
        .map((item, index) => (
          <div
            key={index}
            className={
              activeQuestions < index + 1 ? s.question : s.activeQuestion
            }
          ></div>
        ))}
    </div>
  );
}
