import React, { useEffect } from 'react';
import s from './Final.module.css';
import Button from '../../components/Button/Button';
import plusOne from '../../images/plus-one.png';

export default function Final() {
  const endQuiz = () => {
    const tg = window.Telegram.WebApp;
    tg.close();
    localStorage.clear();
  };
  return (
    <div className={s.final}>
      <div className={s.content}>
        <h1 className={s.title}>Ура, вы это сделали!</h1>
        <p className={s.subtitle}>
          Поздравляем не словом, а делом: вам уже доступно +1 дополнительное
          специальное предложение на выбор за прохождение этой викторины.
        </p>
        <img src={plusOne} alt="" className={s.img} />
      </div>
      <div className={s.button}>
        <Button text="Отлично!" type="button" onClick={endQuiz} />
      </div>
    </div>
  );
}
