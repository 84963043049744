import React, { useEffect, useState } from 'react';
import s from './Answer.module.css';

export default function RadioAnswer({ text, img, correct, answer, check }) {
  const [value, setValue] = useState();
  const [checked, setChecked] = useState(false);
  const [answered, setAnswered] = useState(false);

  useEffect(() => {
    setAnswered(true);
  }, [answer]);

  useEffect(() => {
    if (check === text) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [check]);

  return (
    <label
      className={`${s.answer} ${checked && s.checked} ${correct && answer === text && answered ? s.true : ''} ${
        !correct && answer === text && answered ? s.false : ''
      }`}
      htmlFor={text}
    >
      <div
        className={`${s.circle} ${correct && answer === text && answered ? s.true : ''} ${
          !correct && answer === text && answered ? s.false : ''
        }`}
      >
        <img src={img} alt='' className={s.img} />
      </div>
      <p
        className={`${s.text} ${correct && answer === text && answered ? s.true : ''} ${
          !correct && answer === text && answered ? s.false : ''
        }`}
      >
        {text}
      </p>
      <input
        type='radio'
        name='radio'
        id={text}
        value={text}
        className={s.radioInput}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </label>
  );
}
