import React from 'react';
import s from './Modal.module.css';
import Button from '../Button/Button';
import check from '../../images/modal-check.svg';
import { useNavigate } from 'react-router-dom';

export default function Modal({ title, text, boldText, buttonText, nextQuestion }) {
  const navigate = useNavigate();
  return (
    <div className={s.overlay}>
      <div className={s.container}>
        <div className={s.modal}>
          <img src={check} alt='' className={s.icon} />
          <h2 className={s.title}>{title}</h2>
          <p className={s.text}>{text}</p>
          <p className={s.boldText}>{boldText}</p>
        </div>
      </div>
      <Button
        text={buttonText}
        onClick={() => {
          navigate(`/${nextQuestion}`);
        }}
      />
    </div>
  );
}
