import React from 'react';
import s from './Button.module.css';

export default function Button({ type, text, onClick, form, loader }) {
  return (
    <button
      className={s.button}
      type={type ? type : 'button'}
      onClick={onClick}
      form={form}
      disabled={loader}
    >
      {loader && <div className={s.loader}></div>}
      {!loader && <p className={s.buttonText}>{text}</p>}
    </button>
  );
}
